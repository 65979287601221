:root {
  --variant-condition-true: #56d658;
  --main-blue: #00448c;
  --button-green: #00b33c;
  --button-green-hover: #009933;

  --button-red: #ff4d4d;
  --button-red-hover: #ff3333;

  --button-blue: #3366cc;
  --button-blue-hover: #2952a3;

  --grey-box-shadow: #f5f7fb;

  /* Default blue colors */
  /* --table-row-hover: #eaf0fa;
  --table-head-row: #adc2eb;

  --navbar-background-color: #5c85d6;
  --navbar-icon-color: #ebf0fa;

  --custom-color-1: #d6e2f5; */

  /* Purple set */
  /* --table-row-hover: #dbbfff;
  --table-head-row: #c799ff;

  --navbar-background-color: #a64dff;
  --navbar-icon-color: #f3e6ff;

  --custom-color-1: #d6e2f5; */

  /* Orange set */
  /* --table-row-hover: #ffefe6;
  --table-head-row: #ff944d;

  --navbar-background-color: #ff751a;
  --navbar-icon-color: #ffefe6;

  --custom-color-1: #f9bb84;
  --custom-color-2: #f9bb84; */

  /* Purple set */
  /* --navbar-background-color: #532B88;
  --table-head-row: #9B72CF;
  --custom-color-1: #C8B1E4;
  --table-row-hover: #F4EFFA;
  --navbar-icon-color: #F4EFFA;
  --logo-color: #2F184B;

  --attachment-card-color: #F4EFFA33; */

  /*--------------------------------------------------------------------------*/
  /* Yellow set */
  /* --navbar-background-color: #FDB833;
  --table-head-row: #FDC43F;
  --custom-color-1: #FFDA3D;
  --table-row-hover: #FFE94E;
  --navbar-icon-color: #FFE94E;
  --logo-color: #FFF75E;

  --attachment-card-color: #FFE94E33; */

  /* Blue-green set */
  /* --navbar-background-color: #4D7298;
  --table-head-row: #77A6B6;
  --custom-color-1: #9DC3C2;
  --table-row-hover: #B3D89C;
  --navbar-icon-color: #B3D89C;
  --logo-color: #D0EFB1;

  --attachment-card-color: #B3D89C33; */

  /* Purple-blue set */
  --navbar-background-color: #71A5DE;
  --table-head-row: #83B0E1;
  --custom-color-1: #AECBEB;
  --table-row-hover: #E1ECF7;
  --navbar-icon-color: #E1ECF7;
  --logo-color: #F8F9FB;

  /* --attachment-card-color: #E1ECF733; */
  --attachment-card-color: #f9fbfe;
  --section-content-color: #f1f6fb;

}

em {
  color: inherit;
}

.background-red {
  background-color: #faa9a9;
}

#delete_button {
  padding:0 10px !important;
}

#delete_button span.uk-icon {
  width: 16px;
}

.custom-table tbody tr:hover {
  background-color: var(--table-row-hover);
}

.custom-table thead tr {
  background-color: var(--table-head-row);
}

.custom-table thead th {
  color: #111 !important;
}

.custom-navbar {
  background-color: var(--navbar-background-color) !important;
}

.custom-navbar a {
  color: #fff !important;
}

.custom-navbar .uk-navbar-dropdown a {
  color: #999 !important;
}

.custom-navbar .custom-greetings {
  color: #fff;
  margin-right: 2rem;
}

.custom-navbar .uk-navbar-nav {
  margin-right: 1rem;
}

.custom-button-success {
  background-color: var(--button-green);
  color: #fff;
}

.custom-button-success:hover {
  background-color: var(--button-green-hover);
  color: #fff;
}

.custom-button-danger {
  background-color: var(--button-red);
  color: #fff;
}

.custom-button-danger:hover {
  background-color: var(--button-red-hover);
  color: #fff;
}

.custom-table .uk-icon-button,
.product-attachments-card .uk-card-footer .uk-icon-button,
.bot-logs.uk-icon-button {
  background-color: var(--navbar-icon-color);
  color: #000 !important;
  border: 1px solid var(--table-head-row);
  cursor: pointer;
}

.custom-table .uk-icon-button:hover,
.product-attachments-card .uk-card-footer .uk-icon-button:hover,
.bot-logs.uk-icon-button:hover {
  background-color: var(--custom-color-1);
  border: 1px solid var(--navbar-background-color);
}


.uk-logo svg {
  width: 2rem;
  fill: var(--navbar-icon-color);
  margin-left: 1rem;
}

.custom-button-info {
  background-color: var(--button-blue);
}

.custom-button-info:hover {
  background-color: var(--button-blue-hover);
}

.uk-notification-message-success {
  background-color: #f7fff9;
  color: var(--button-green);
}

.custom-arrow-left svg, .custom-arrow-right svg {
  width: 6rem;
  height: 4rem;
  cursor: pointer;
}

#screen-2 > * {
  /* transition: height 0.25s linear; */
  animation: fadein 0.5s !important;
}

@keyframes fadein {
  from { opacity: 0}
  to   { opacity: 1}
}

.custom-table tr {
  animation: fadein 1s;
}

.custom-nav-icon svg {
  width: 30px;
  height: 30px;
}

.uk-heading-line {
  animation: fadein 1s;
}

.custom-section-outer {
  padding-top: 2rem !important;
}

#adminSettingsForm legend {
  margin-bottom: 25px !important;
}


#filterOrderByDiv,
#filterOrderDiv,
#filterCreatedDiv,
#filterActionDiv,
#filterRoleDiv,
#filterStatusDiv,
#filterTypeDiv,
#filterCrmBuyerIdDiv,
#filterUpsellsCategoryIdDiv,
#filterDashboardUserDiv,
#filterPixelDiv ,
#filterResponseCodeDiv {
  display: flex;
  flex-direction: row;
  position: relative;
  margin-left: 1rem;
}

#filterSearchDiv {
  font-size: 14px;
}

#filterSearchDiv span {
  color: #000;
}

#filterActionDiv img {
  width: 16px;
  position: absolute;
  top: 12px;
  left: 12px
}

#filterOrderByDiv img {
  width: 20px;
  position: absolute;
  top: 10px;
  left: 10px
}

#filterOrderDiv img {
  width: 16px;
  position: absolute;
  top: 12px;
  left: 12px
}

#filterRoleDiv img,
#filterDashboardUserDiv img {
  width: 16px;
  position: absolute;
  top: 12px;
  left: 12px
}

#filterStatusDiv img {
  width: 16px;
  position: absolute;
  top: 12px;
  left: 12px
}

#filterTypeDiv img {
  width: 16px;
  position: absolute;
  top: 12px;
  left: 12px
}

#filterCrmBuyerIdDiv img {
  width: 16px;
  position: absolute;
  top: 12px;
  left: 12px
}

#filterUpsellsCategoryIdDiv img {
  width: 16px;
  position: absolute;
  top: 12px;
  left: 12px
}

#filterPixelDiv img {
  width: 16px;
  position: absolute;
  top: 12px;
  left: 12px
}

#filterResponseCodeDiv img {
  width: 16px;
  position: absolute;
  top: 12px;
  left: 12px
}

#filterOrderByDiv select,
#filterOrderDiv select,
#filterStatusesDiv select,
#filterActionDiv select,
#filterRoleDiv select,
#filterStatusDiv select,
#filterTypeDiv select,
#filterCrmBuyerIdDiv select,
#filterUpsellsCategoryIdDiv select,
#filterDashboardUserDiv select,
#filterPixelDiv select,
#filterResponseCodeDiv select {
  font-size: 14px;
  padding-left: 2rem;
}

#filterCreatedDiv input {
  font-size: 14px;
  /* padding-left: 2rem; */
}

#filterCreatedDiv img {
  width: 16px;
  position: absolute;
  top: 12px;
  left: 12px;
}

.uk-breadcrumb li
{
  max-width: 24rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
}

.blink {
  animation: blink-animation 2s steps(20, start) 5;
  -webkit-animation: blink-animation 2s steps(20, start) 5;
}
@keyframes blink-animation {
  50% {
    background-color: #c1d0f0;
  }
}
@-webkit-keyframes blink-animation {
  50% {
    background-color: #c1d0f0;
  }
}

.custom-admin-change-password > div {
  background: var(--custom-color-1);
}

.uk-heading-line span {
  padding-bottom: 0.3rem;
}

#loginForm #loginBox,
#loginForm #passwordBox,
#loginForm .login-alert {
  margin-left: 25%;
  margin-right: 25%;
}

section {
  background-color: var(--custom-color-1);
  color: #444;
}

#adminPasswordForm legend {
  color: #444;
}


.uk-color {
  width: 2.2rem;
  height: 2.2rem;
  background-color: #fff;
  border: 1px solid #e8e8e8;
  cursor: pointer;
}

.uk-lightbox .uk-lightbox-items img {
  max-width: 80%;
}

.uk-lightbox {
  background: rgba(0,0,0,0.7) !important;
}

.product-attachments-card .uk-card-footer .uk-icon-button {
  width: 2rem !important;
  height: 2rem !important;
}

.product-attachments-card:hover {
  /* box-shadow: 0 14px 25px var(--custom-color-1) !important; */
  box-shadow: 0 5px 20px var(--custom-color-1) !important;
}

.product-attachments-card {
  background: var(--attachment-card-color) !important;
}

.logs-table td textarea {
  min-width: 100%;
  min-height: 2rem;
}

#hiddenBox input,
#customResultBox input {
  margin-left: 0.5rem;
}


.show {
  display: block !important;
  /* animation: fadein 1s !important; */
}

.hide {
  display: none !important;
}

/* Editor styles START */

.tox .tox-mbtn {
  font-size: 12px !important;
}

.tox-tinymce {
  border-radius: 0 !important;
}

/* Editor styles END */


/* Product features START */
.product-features-wrapper .product-features-add,
.product-features-wrapper .uk-icon,
.offer-products-wrapper .offer-products-add,
.offer-products-wrapper .uk-icon {
  cursor: pointer;
}

.trash-button {
  color: var(--button-red);
}

.trash-button:hover {
  color: var(--button-red-hover);
}

.product-features-wrapper,
.offer-products-wrapper {
  max-height: 415px;
  overflow-y: auto;
  scrollbar-width: thin;
  padding: 5px 5px 15px 0;
}
/* Product features END */

/* Product attachments START */

.product-attachments-slider {
  padding: 15px;
}

.product-attachments-slider-container {
  margin: -11px -25px -39px -25px;
  padding: 11px 25px 39px 25px;
}

.product-attachments-card .uk-icon-button {
  margin-left: 1px;
  margin-right: 1px;
}

.product-attachments-card span.product-attachment-arrow-left {
  margin-right: 0.3rem;
  cursor: pointer;
}

.product-attachments-card span.product-attachment-arrow-right {
  margin-left: 0.3rem;
  cursor: pointer;
}

/* Logs table START */

.logs-table-filter #filterOrderByDiv,
.logs-table-filter #filterOrderDiv,
.logs-table-filter #filterSectionDiv,
.logs-table-filter #filterUserIdDiv,
.logs-table-filter #filterCreatedDiv {
  display: flex;
  flex-direction: row;
  position: relative;
  margin-left: 1rem;
}

.logs-table-filter #filterSearchDiv {
  font-size: 14px;
}

.logs-table-filter #filterSearchDiv span {
  color: #000;
}

.logs-table-filter #filterOrderByDiv img {
  width: 20px;
  position: absolute;
  top: 10px;
  left: 10px
}

.logs-table-filter #filterOrderDiv img {
  width: 16px;
  position: absolute;
  top: 12px;
  left: 12px
}

.logs-table-filter #filterSectionDiv img {
  width: 16px;
  position: absolute;
  top: 12px;
  left: 12px
}

.logs-table-filter #filterUserIdDiv img {
  width: 16px;
  position: absolute;
  top: 12px;
  left: 12px
}

.logs-table-filter #filterOrderByDiv select,
.logs-table-filter #filterOrderDiv select,
.logs-table-filter #filterSectionDiv select,
.logs-table-filter #filterUserIdDiv select {
  font-size: 14px;
  padding-left: 2rem;
}

.logs-table-filter #filterCreatedDiv input {
  font-size: 14px;
}

.logs-table-filter #filterCreatedDiv img {
  width: 16px;
  position: absolute;
  top: 12px;
  left: 12px;
}

.logs-table td pre,
.logs-table td > textarea {
  max-width: 300px !important;
  width: 300px !important;
  /* height: 60px !important; */
  font-size: 10px !important;
}

.logs-table-filter #filterSection,
.logs-table-filter #filterUserId {
  max-width: 16rem !important;
}

.logs-table-filter #filterCreated {
  padding-left: 0.9rem;
}

/* Logs table END */

/* Arrows START */

/* .uk-icon {
  cursor: pointer;
} */

.arrows {
  max-width: 2rem;
  margin-left: auto;
  margin-right: auto;
}

/* Arrows END */

.icon-success, .color-success {
  color: var(--button-green);
}

.icon-danger, .color-danger {
  color: var(--button-red);
}

/* Upsell categories START */
.upsell-categories-wrapper .upsell-categories-add,
.upsell-categories-wrapper .uk-icon,
.site-upsells-wrapper .site-upsells-add,
.site-upsells-wrapper .uk-icon {
  cursor: pointer;
}

.trash-button {
  color: var(--button-red);
}

.trash-button:hover {
  color: var(--button-red-hover);
}

.upsell-categories-wrapper,
.site-upsells-wrapper {
  /* max-height: 415px; */
  max-height: 915px;
  overflow-y: auto;
  scrollbar-width: thin;
  padding: 5px 5px 15px 0;
}
/* Upsell categories END */

/* Upsell attachments START */

.attachments-slider {
  padding: 15px;
}

.attachments-slider-container {
  margin: -11px -25px -39px -25px;
  padding: 11px 25px 39px 25px;
}

.attachments-card .uk-icon-button {
  margin-left: 1px;
  margin-right: 1px;
}

.attachments-card span.attachment-arrow-left {
  margin-right: 0.3rem;
  cursor: pointer;
}

.attachments-card span.attachment-arrow-right {
  margin-left: 0.3rem;
  cursor: pointer;
}

.attachments-card .uk-card-footer .uk-icon-button {
  background-color: var(--navbar-icon-color);
  color: #000 !important;
  border: 1px solid var(--table-head-row);
  cursor: pointer;
}

.attachments-card .uk-card-footer .uk-icon-button:hover {
  background-color: var(--custom-color-1);
  border: 1px solid var(--navbar-background-color);
}

/* Upsell attachments END */

/* Upsell START */

#statusBox input {
  margin-left: 0.5rem;
}

.upsells-table tr {
  position: relative;
}

.upsells-table-img {
  width: 6rem;
  height: 6rem;
  overflow: visible;
}

.upsells-table-img img {
  width: 6rem;
  height: 6rem;
  object-fit: cover;
  object-position: center;
  /* transition: height .5s ease-in; */
}

/* .upsells-table-img:hover img {
  position: absolute;
  width: 20rem;
  height: auto;
  top: 16px;
  z-index: 2;
} */

/* Upsell END */

/* Site variations START */

.site-variations-wrapper .site-variation-add,
.site-variations-wrapper .site-variations-options-wrapper .site-variation-option-add,
.site-variations-wrapper .site-variations-options-wrapper .site-variation-option-title,
.site-variations-wrapper .uk-icon,
.site-variations-wrapper .site-variation-open-title {
  cursor: pointer;
}

.site-variations-wrapper .arrows,
.site-variations-options-wrapper .arrows {
  max-width: 2rem;
  margin-left: auto;
  margin-right: auto;
}

.site-variations-wrapper .site-variation-content,
.site-variations-options-wrapper .site-variation-option-content {
  padding-top: 0;
  padding-bottom: 0;
  transform: scaleY(0);
  transform-origin: top;
  transition: transform 0.26s ease;
}

.site-variations-wrapper .site-variation-content[data-opened='1'],
.site-variations-options-wrapper .site-variation-option-content[data-opened='1'] {
  padding-top: 13px;
  padding-bottom: 20px;
  transform: scaleY(1);
  transition: transform 0.26s ease;
}

.site-variation-content {
  background-color: var(--section-content-color);
}

/* .site-variations-wrapper .site-variation-open-title svg {
  width: 1.5rem;
} */


/* Site variations END */

/* Site files START */

.site-files-wrapper pre {
  height: 300px;
  overflow-y: scroll;
  scrollbar-width: thin;
}

/* Site files END */

/* Sites table START */

.sites-url a {
  top: -1px;
  position: relative;
}

/* Sites table END*/

/* Facebook server logs START */
.server-logs-table td pre,
.server-logs-table td > textarea {
  max-width: 200px !important;
  width: 200px !important;
  /* height: 60px !important; */
  font-size: 10px !important;
}

.server-logs-table-filter #filterSearchDiv {
  max-width: 10rem !important;
}

.server-logs-table-filter #filterType {
  max-width: 10rem !important;
}

.server-logs-table-filter #filterPixel {
  max-width: 8.5rem !important;
}

.server-logs-table-filter #filterResponseCode {
  max-width: 11rem !important;
}

.server-logs-table-filter #filterCreated {
  padding-left: 0.9rem;
}

.server-logs-table > tbody > tr > td:nth-child(2) {
  max-width: 65px;
  word-wrap: break-word;
}

.server-logs-table > tbody > tr > td:nth-child(4) {
  max-width: 140px;
  word-wrap: break-word;
}

/* Facebook server logs END */

/* Site-catalogue START */

#site-catalogue-form #image-loader > div {
  transition: all .5s ease;
  bottom: -100%;
}

#site-catalogue-form #image-loader:hover > div {
  bottom: 5%;
}

#site-catalogue-form #image-loader a,
#site-catalogue-form #image-loader picture,
#site-catalogue-form #image-loader img {
  width: 100%;
  height: 100%;
}

#site-catalogue-form #image-loader .uk-icon {
  width: 2.5rem;
  height: 2.5rem;
  cursor: pointer;
}

#site-catalogue-form #image-loader .uk-icon.action-edit {
  background-color: var(--navbar-icon-color);
  color: #000 !important;
  border: 1px solid var(--table-head-row);
}

#site-catalogue-form #image-loader .uk-icon.action-edit:hover {
  background-color: var(--custom-color-1);
  border: 1px solid var(--navbar-background-color);
}

#site-catalogue-form #image-loader .uk-icon.action-delete {
  background-color: var(--button-red);
  color: #fff !important;
  border: 1px solid var(--button-red-hover);

  margin-left: 25px;
}

#site-catalogue-form #image-loader .uk-icon.action-delete:hover {
  background-color: var(--button-red-hover);
}


/* Site-catalogue END */
